<template>
    <div class="container" v-if="record">
        <div class="row">
            <div class="col-md-8 col-md-offset-2 text-center heading-section animate-box">
                <h3>{{ record.title }}</h3>
                <p>{{ record.description }}</p>
            </div>
        </div>
        <div class="row row-bottom-padded-md">
            <div 
                :class="'col-md-' + (record.image && record.image.url ? 8 : 12) + ' animate-box'"
            >
                <span v-html="record.content"></span>
                <a 
                    href="javascript:void(0)"
                    v-if="record.price "
                >
                    &#8358; {{ record.price }} 
                </a>
            </div>
            <div 
                class="col-md-4 animate-box"
                v-if="record.image && record.image.url"
            >
                <img 
                    class="img-responsive grid-image" 
                    v-lazy="record.image.url" 
                    :alt="record.image.description" 
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTemplate',
        props: ['record']
    }
</script>

<style lang="scss" scoped>

</style>