<template>
    <div>
        <!-- Back to top button -->
        <button
            type="button"
            class="btn btn-danger btn-floating btn-lg back-hover"
            id="btn-back-to-top"
            @click="scrollToTop"
            v-show="scroll > 50"
        >
            <i class="fa fa-arrow-up"></i>
        </button>

        <!-- the whatsapp logo included here -->
        <a 
            :href="whatsappUrl"
            class="btn-floating whatsapp-logo"
        >
            <img 
                v-lazy="'/images/whatsapp.png'" 
                class="whatsapp-image"
            />
        </a>


        <footer id="footer-slanted">   
            <div id="footer">
                <div class="container">
                    <div class="row row-bottom-padded-md">
                        <div class="col-md-3 col-sm-3 col-xs-12 fh5co-footer-link">
                            <h3 v-if="params.name">{{ params.name }}</h3>
                            <p v-if="params.description">
                                {{ params.description }}
                            </p>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12 fh5co-footer-link">
                            <h3>Recent Posts &amp; Flight Updates</h3>
                            <ul v-if="updates && updates.length > 0">
                                <li
                                    v-for="update in updates"
                                    :key="update.id"
                                >
                                    <router-link
                                        :to="'/menu/' + blogCat.route + '/' + update.slug"
                                    >
                                        {{ update.title }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-2 col-sm-2 col-xs-12 fh5co-footer-link">
                            <h3>Quick Links</h3>
                            <ul>
                                <li><router-link to="/">Home</router-link></li>
                                <li><router-link to="/services">Services</router-link></li>
                                <li><router-link to="/blog">Blog</router-link></li>
                                <li><router-link to="/contact">Contact Us</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            </ul>
                        </div>
                        <div class="col-md-3 col-sm-3 col-xs-12 fh5co-footer-link">
                            <h3>Customer Support</h3>
                            <p>
                                <span>
                                    <i class="fa fa-phone"></i>&nbsp;&nbsp;
                                    <a 
                                        :href="'tel:'+params.phone"
                                        v-if="params.phone"
                                    >
                                        {{ params.phone }} 
                                    </a>
                                    <br><br>
                                    <i class="fa fa-envelope"></i>&nbsp;&nbsp;
                                    <a 
                                        :href="'mailto:'+params.email"
                                        v-if="params.email"
                                    >
                                        {{ params.email }}
                                    </a>
                                    <br><br>
                                    24/7 Dedicated Customer Support
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-md-offset-3 text-center">
                            <p class="fh5co-social-icons">
                                <a 
                                    :href="params.twitter"
                                >
                                    <i class="fa fa-twitter"></i>
                                </a>
                                <a 
                                    :href="params.facebook"
                                >
                                    <i class="fa fa-facebook"></i>
                                </a>
                                <a 
                                    :href="params.instagram"
                                >
                                    <i class="fa fa-instagram"></i>
                                </a>
                                <!-- <a 
                                    :href="params.youtube"
                                >
                                    <i class="fa fa-youtube"></i>
                                </a> -->
                                <a 
                                    :href="whatsappUrl"
                                >
                                    <i class="fa fa-whatsapp"></i>
                                </a>
                            </p>
                            <p>Copyright {{ year }}. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'Footer',
        props: ['params', 'blog'],
        data() {
            return {
                scroll: null
            }
        },
        computed: {
            updates() {
                return this.blog && this.blog.records ? this.blog.records : [];
            },
            blogCat() {
                return this.blog && this.blog.category ? this.blog.category : {};
            },
            year() {
                return moment().year();
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            whatsappUrl() {
                if(this.params) {
                    if(this.isMobile) {
                        return 'https://api.whatsapp.com/send?phone=' + this.params.whatsapp
                    } else {
                        return '/contact#whatsapp';
                    }
                }
                return null;
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            },
            handleScroll(e) {
                this.scroll = window.scrollY || window.scrollTop
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style lang="scss" scoped>

</style>